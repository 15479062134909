export const chordShuffle = [
  /* C Major */
  {
    one: ["C2", "E3", "C4", "D4", "G4"],
    two: ["G2", "D3", "B3", "D4", "G4"],
    three: ["A2", "E3", "G3", "C4", "E4"],
    four: ["F2", "F3", "A3", "C4", "F4"],
    scale: "c major",
  },
  {
    one: ["C2", "G3", "C4", "E4"],
    two: ["A2", "A3", "C4", "E4"],
    three: ["F2", "A3", "C4", "F4"],
    four: ["F2", "A3", "C4", "F4"],
    scale: "c major",
  },
  {
    one: ["C2", "E3", "A3", "C4", "G4"],
    two: ["F2", "F3", "A3", "C4", "G4"],
    three: ["C2", "E3", "G3", "C4", "G4"],
    four: ["C2", "E3", "G3", "C4", "G4"],
    scale: "c major",
  },
  {
    one: ["C2", "G3", "C4", "E4"],
    two: ["B2", "G3", "B3", "D4"],
    three: ["A2", "A3", "C4", "E4"],
    four: ["F2", "F3", "A3", "C4"],
    scale: "c major",
  },
  {
    one: ["A2", "G3", "C4", "E4"],
    two: ["G2", "G3", "B3", "D4"],
    three: ["C2", "G3", "C4", "E4"],
    four: ["F2", "A3", "C4", "E4"],
    scale: "c major",
  },
  {
    one: ["C2", "G3", "C4", "E4"],
    two: ["G2", "G3", "B3", "E4"],
    three: ["A2", "A3", "C4", "E4"],
    four: ["F2", "A3", "C4", "D4"],
    scale: "c major",
  },
  {
    one: ["G2", "G3", "A#3", "D4", "F4"],
    two: ["F2", "F3", "A3", "C4", "G4"],
    three: ["C2", "E3", "C4", "D4", "G4"],
    four: ["C2", "E3", "C4", "D4", "G4"],
    scale: "c major",
  },
  {
    one: ["C2", "G3", "C4", "E4"],
    two: ["G2", "G3", "B3", "D4"],
    three: ["F2", "A3", "C4", "E4"],
    four: ["D2", "A3", "C4", "F4"],
    scale: "c major",
  },
  {
    one: ["F2", "F3", "A3", "C4", "E4"],
    two: ["F2", "F3", "G#3", "C4", "D4"],
    three: ["C2", "E3", "G3", "C4", "E4"],
    four: ["G2", "E3", "G3", "B3", "E4"],
    scale: "c major",
  },
  {
    one: ["D2", "A3", "D4", "F4"],
    two: ["F2", "A3", "C4", "E4"],
    three: ["C2", "G3", "C4", "E4"],
    four: ["G2", "G3", "B3", "D4"],
    scale: "c major",
  },
  {
    one: ["C2", "E3", "G4", "C4", "D4"],
    two: ["G2", "D3", "G3", "B3", "D4"],
    three: ["D2", "D3", "A3", "C4", "F4"],
    four: ["F2", "F3", "A3", "C4", "E4"],
    scale: "c major",
  },
  {
    one: ["C2", "G3", "C4", "D4", "G4"],
    two: ["F2", "F3", "A3", "C4", "F4"],
    three: ["A2", "E3", "A3", "C4", "G4"],
    four: ["F2", "F3", "A3", "C4", "F4"],
    scale: "c major",
  },
  {
    one: ["A2", "A3", "C4", "E4"],
    two: ["F2", "A3", "C4", "F4"],
    three: ["G2", "G3", "B3", "D4"],
    four: ["E2", "G3", "B3", "E4"],
    scale: "a minor",
  },
  {
    one: ["F2", "F3", "C4", "E4", "A4"],
    two: ["D2", "A3", "C4", "F4", "A4"],
    three: ["A2", "E3", "C4", "D4", "G4"],
    four: ["C2", "E3", "B3", "D4", "G4"],
    scale: "a minor",
  },
  {
    one: ["A2", "A3", "C4", "E4", "A4"],
    two: ["G2", "G3", "B3", "D4", "A4"],
    three: ["F2", "F3", "C4", "E4", "A4"],
    four: ["G2", "G3", "B3", "D4", "A4"],
    scale: "a minor",
  },
  {
    one: ["A2", "A3", "C4", "E4"],
    two: ["C3", "G3", "C4", "E4"],
    three: ["D2", "F#3", "A3", "D4", "E4"],
    four: ["C2", "G3", "C4", "E4"],
    scale: "a minor",
  },
  {
    one: ["A2", "E3", "A3", "C4", "E4"],
    two: ["E2", "E3", "B3", "D4", "G4"],
    three: ["F2", "F3", "A3", "C4", "E4"],
    four: ["D2", "D3", "A3", "C4", "F4"],
    scale: "a minor",
  },
  {
    one: ["A2", "A3", "C4", "E4"],
    two: ["G2", "B3", "D4", "G4"],
    three: ["D2", "A3", "C4", "F4"],
    four: ["F2", "A3", "C4", "F4"],
    scale: "a minor",
  },
  {
    one: ["F2", "F3", "C4", "E4", "A4"],
    two: ["G2", "G3", "B3", "D4", "G4"],
    three: ["E2", "E3", "B3", "D4", "G4"],
    four: ["A2", "A3", "C4", "E4", "A4"],
    scale: "a minor",
  },
  {
    one: ["A2", "A3", "C4", "E4", "A4"],
    two: ["C2", "G3", "C4", "E4", "G4"],
    three: ["F2", "A3", "C4", "F4", "A4"],
    four: ["E2", "E3", "B3", "D4", "G#4"],
    scale: "a minor",
  },
  {
    one: ["A2", "A3", "C4", "E4", "A4"],
    two: ["G2", "G3", "B3", "D4", "G4"],
    three: ["D2", "F3", "C4", "D4", "A4"],
    four: ["E2", "G3", "B3", "D4", "G4"],
    scale: "a minor",
  },
  {
    one: ["D2", "A3", "D4", "F4"],
    two: ["F2", "A3", "C4", "F4"],
    three: ["A2", "A3", "C4", "E4"],
    four: ["G2", "G3", "B3", "D4"],
    scale: "a minor",
  },
  {
    one: ["A2", "A3", "C4", "E4"],
    two: ["F2", "A3", "C4", "F4"],
    three: ["C2", "G3", "C4", "E4"],
    four: ["G2", "G3", "B3", "D4"],
    scale: "a minor",
  },
  {
    one: ["A2", "A3", "C4", "E4"],
    two: ["G2", "G3", "C4", "E4"],
    three: ["F#2", "A3", "C4", "E4"],
    four: ["F2", "A3", "C4", "E4"],
    scale: "a minor",
  },
];
