export const defaultAvatar = [
  "/images/avatar/1.png",
  "/images/avatar/2.png",
  "/images/avatar/3.png",
  "/images/avatar/4.png",
  "/images/avatar/5.png",
  "/images/avatar/6.png",
  "/images/avatar/7.png",
  "/images/avatar/8.png",
  "/images/avatar/9.png",
];
